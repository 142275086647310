
import { defineComponent, computed } from 'vue'
import { NButton, NSpin, NModal, NDivider, NCard, NAlert } from 'naive-ui'
import { ethers } from 'ethers'

import { useStore } from '@/store'
import {
  getNetworkDomainIDByName,
  isValidAddress,
  isNativeToken,
  getMinAmount
} from '@/utils'
import { sendButton, sendButtonTooltip, disclaimer } from '../../i18n/main.json'

export default defineComponent({
  components: {
    NButton,
    NSpin,
    NDivider,
    NModal,
    NCard,
    NAlert
  },

  data () {
    return {
      showModal: false,
      minAmount: 0,

      // copy
      disclaimer
    }
  },

  setup () {
    const store = useStore()
    const walletAddress = computed(() => store.state.wallet.address)
    const walletNetwork = computed(() => store.state.wallet.network)
    const walletToken = computed(() => store.state.wallet.token)
    const sendAmount = computed(() => store.state.userInput.sendAmount)
    const recipientAddress = computed(() => store.state.userInput.recipientAddress)
    const destinationNetwork = computed(() => store.state.userInput.destinationNetwork)
    const sending = computed(() => store.state.multiProvider.sending)

    return {
      recipientAddress,
      walletAddress,
      walletNetwork,
      walletToken,
      sendAmount,
      destinationNetwork,
      sending,
      store,

      // copy
      sendButton,
      sendButtonTooltip
    }
  },

  async mounted () {
    await this.getMinAmt()
  },

  methods: {
    send () {
      this.showModal = false

      try {
        const payload = {
          isNative: isNativeToken(this.walletNetwork, this.walletToken),
          originNetwork: getNetworkDomainIDByName(this.walletNetwork),
          destNetwork: getNetworkDomainIDByName(this.destinationNetwork! as string),
          asset: this.walletToken.tokenIdentifier,
          amnt: ethers.utils.parseUnits(this.sendAmount.toString(), this.walletToken.decimals),
          recipient: this.recipientAddress
        }
        this.store.dispatch('send', payload)

        // set send amount to 0 if success
        this.store.dispatch('setSendAmount', 0)
      } catch (e) {
        console.error('send error:', e)
      }
    },

    sendDisabled () {
      // disable if token is native to the source chain
      if (getNetworkDomainIDByName(this.walletNetwork) === getNetworkDomainIDByName(this.walletToken.nativeNetwork)) {
        return true
      }

      // disable for bad amount
      if (!this.sendAmount || this.sendAmount === 0) return true

      // disable for bad recipient address
      if (!isValidAddress(this.recipientAddress)) return true

      // disable for sanctioned recipient addresses
      if ([this.walletAddress, this.recipientAddress].some(address =>
        this.store.state.sanctionedList.list.some(sanctionedAddress =>
          sanctionedAddress.toLowerCase() === address.toLowerCase()))) { return true }

      // disable if less than minimum amount
      if (this.sendAmount < this.minAmount) return true

      // disable for null network or while sending
      return this.destinationNetwork === null || this.sending
    },

    async getMinAmt () {
      // return 0 if in development
      if (process.env.VUE_APP_OPTICS_ENVIRONMENT !== 'production') return 0

      try {
        const min = await getMinAmount(this.walletToken.coinGeckoId)
        this.minAmount = min
      } catch (e) {
        console.log(e)
        this.minAmount = this.walletToken.minAmt
      }
    },

    showNotPolygonDisclaimer () {
      if (this.destinationNetwork?.toLowerCase() === 'polygon') {
        return this.walletToken.nativeNetwork === 'ethereum'
      }
      return false
    },
    isUSDC () {
      return this.walletToken.name === 'USDC'
    }
  },

  watch: {
    walletToken () {
      this.getMinAmt()
    }
  }
})
