
import { defineComponent, computed } from 'vue'
import { NConfigProvider, darkTheme } from 'naive-ui'
import { BigNumber } from 'ethers'

import Nav from './components/Nav.vue'
import Modal from './components/Modal.vue'
import Footer from './components/Footer.vue'
import LogoSpin from './components/LogoSpin.vue'
import PopupList from './components/Alerts/PopupList.vue'
import { useStore } from './store'
import { getNetworkByChainID } from './utils'

const themeOverrides = {
  common: {
    secondaryColor: '#E24084',
    secondaryColorHover: '#B14EA6'
  },
  Button: {
    textColorBase: '#FFF',
    primaryColor: '#E24084',
    baseColor: '#E24084'
  }
}

export default defineComponent({
  components: {
    NConfigProvider,
    Nav,
    Modal,
    LogoSpin,
    Footer,
    PopupList
  },
  data () {
    return {
      darkTheme,
      themeOverrides
    }
  },
  created () {
    const store = useStore()
    store.dispatch('fetchSanctionedList')
  },
  setup () {
    const store = useStore()
    const { ethereum } = window as any

    store.dispatch('instantiateOptics')

    setTimeout(() => {
      if (ethereum) {
        const connected = ethereum.isConnected()
        if (connected) {
          store.dispatch('connectWallet')
        }
      }
    }, 3000)

    setInterval(() => {
      if (store.state.wallet.connected) {
        store.dispatch('getBalanceFromWallet')
      }
    }, 10000)

    // listens for user chain change event
    if (ethereum) {
      ethereum.on('chainChanged', async (chainId: number) => {
        console.log('network change')

        try {
          // get name of network and set in store
          const id = BigNumber.from(chainId).toNumber()
          const network = getNetworkByChainID(id).name
          await store.dispatch('setWalletNetwork', getNetworkByChainID(id).name)

          // update signer
          store.dispatch('registerSigner', network.toLowerCase())
        } catch (e) {
          store.dispatch('setNetworkUnsupported', true)
          console.error(e)
        }
        await store.dispatch('getBalanceFromWallet')
      })
      ethereum.on('accountsChanged', () => {
        // everything changes, easiest to reload
        location.reload()
      })
    }

    return {
      loading: computed(() => !store.state.wallet.connected),
      networkUnsupported: computed(() => store.state.wallet.networkUnsupported)
    }
  }
})
