// TODO: make script that compiles this
const ethereum = {
  CUSD: '0xeccd71699e091382b17c17d98f568d7aca0b72ed',
  CELO: '0x1e33dba7cd47e79c4385ba39442a693b910a0a8a',
  SUSHI: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
  WETH: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  WBTC: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  UNI: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
  LINK: '0x514910771af9ca656af840dff83e8264ecf986ca',
  USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  USDCPOS: '0xfbad3c3e54147ba4c88ccf132680c7a5bda3bdfe',
  USDT: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  DAI: '0x6b175474e89094c44da98b954eedeac495271d0f',
  CEUR: '0x0000000000000000000000000000000000000000',
  WMATIC: '0x29476d23322e9b7522983cba0503bc43b2d07b7d',
  CRV: '0xd533a949740bb3306d119cc777fa900ba034cd52',
  SYMM: '0x57db3ffca78dbbe0efa0ec745d55f62aa0cbd345',
  SOURCE: '0x8d2b3350edf2e8a0bf9deee1dd215bcebc772048'
}

const celo = {
  SUSHI: '0xd15ec721c2a896512ad29c671997dd68f9593226',
  WETH: '0xe919f65739c26a42616b7b8eedc6b5524d1e3ac4',
  WBTC: '0xbe50a3013a1c94768a1abb78c3cb79ab28fc1ace',
  UNI: '0x0000000000000000000000000000000000000000',
  LINK: '0x0000000000000000000000000000000000000000',
  USDC: '0x2a3684e9dc20b857375ea04235f2f7edbe818fa7',
  USDCPOS: '0xcc82628f6a8defa1e2b0ad7ed448bef3647f7941',
  USDT: '0xb020d981420744f6b0fedd22bb67cd37ce18a1d5',
  DAI: '0xe4fe50cdd716522a56204352f00aa110f731932d',
  CELO: '0x471ece3750da237f93b8e339c536989b8978a438',
  CUSD: '0x765DE816845861e75A25fCA122bb6898B8B1282a',
  CEUR: '0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73',
  WMATIC: '0x3af556b48469d2398ab7be1563a0cfd80ea4ac84',
  CRV: '0x0a7432cF27F1aE3825c313F3C81e7D3efD7639aB',
  SYMM: '0x7c64aD5F9804458B8c9F93f7300c15D55956Ac2a',
  SOURCE: '0x74c0C58B99b68cF16A717279AC2d056A34ba2bFe'
}

const polygon = {
  SUSHI: '0xd15ec721c2a896512ad29c671997dd68f9593226',
  WETH: '0xb020d981420744f6b0fedd22bb67cd37ce18a1d5',
  WBTC: '0xbe50a3013a1c94768a1abb78c3cb79ab28fc1ace',
  UNI: '0x0000000000000000000000000000000000000000',
  LINK: '0x0000000000000000000000000000000000000000',
  USDC: '0xe919f65739c26a42616b7b8eedc6b5524d1e3ac4',
  USDCPOS: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  USDT: '0xcc82628f6a8defa1e2b0ad7ed448bef3647f7941',
  DAI: '0xe4fe50cdd716522a56204352f00aa110f731932d',
  CELO: '0x2a3684e9dc20b857375ea04235f2f7edbe818fa7',
  CUSD: '0x7c64ad5f9804458b8c9f93f7300c15d55956ac2a',
  CEUR: '0x0000000000000000000000000000000000000000',
  WMATIC: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
}

export default {
  celo,
  ethereum,
  polygon
}
