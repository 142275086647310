import CeloIcon from '../assets/token-logos/CELO.png'
import wETHIcon from '../assets/token-logos/weth.png'
import USDTIcon from '../assets/token-logos/USDT.png'
import USDCIcon from '../assets/token-logos/USDC.png'
import DAIIcon from '../assets/token-logos/DAI.png'
import cEURIcon from '../assets/token-logos/cEUR.png'
import cUSDIcon from '../assets/token-logos/cUSD.png'
import SushiIcon from '../assets/token-logos/sushi.png'
// import MaticIcon from '../assets/token-logos/MATIC.png'
// import KNXIcon from '../assets/token-logos/KNX.png'

import testnetTokens from './tokens.dev'
import { TokenMetadata, NetworkMetadata } from './config.types'
import representationsDev from './representations.dev'

export const tokens: { [key: string]: TokenMetadata } = {
  CELO: {
    nativeNetwork: 'alfajores',
    symbol: 'CELO',
    name: 'Alfajores CELO',
    icon: CeloIcon,
    decimals: 18,
    coinGeckoId: 'celo',
    tokenIdentifier: testnetTokens.CELO,
    nativeOnly: false,
    minAmt: 1.8
  },
  CEUR: {
    nativeNetwork: 'alfajores',
    symbol: 'cEUR',
    name: 'cEUR',
    icon: cEURIcon,
    decimals: 18,
    coinGeckoId: 'celo-euro',
    tokenIdentifier: testnetTokens.CEUR,
    nativeOnly: false,
    minAmt: 8.5
  },
  CUSD: {
    nativeNetwork: 'alfajores',
    symbol: 'cUSD',
    name: 'cUSD',
    icon: cUSDIcon,
    decimals: 18,
    coinGeckoId: 'celo-dollar',
    tokenIdentifier: testnetTokens.CUSD,
    nativeOnly: false,
    minAmt: 10
  },
  SUSHI: {
    nativeNetwork: 'kovan',
    symbol: 'SUSHI',
    name: 'SUSHI',
    icon: SushiIcon,
    decimals: 18,
    coinGeckoId: 'sushi',
    tokenIdentifier: testnetTokens.SUSHI,
    nativeOnly: false,
    minAmt: 1
  },
  KWETH: {
    nativeNetwork: 'kovan',
    symbol: 'kWETH',
    name: 'Kovan WETH',
    icon: wETHIcon,
    decimals: 18,
    coinGeckoId: 'weth',
    tokenIdentifier: testnetTokens.kWETH,
    nativeOnly: false,
    minAmt: 0.0028
  },
  RWETH: {
    nativeNetwork: 'rinkeby',
    symbol: 'rWETH',
    name: 'Rinkeby WETH',
    icon: wETHIcon,
    decimals: 18,
    coinGeckoId: 'weth',
    tokenIdentifier: testnetTokens.rWETH,
    nativeOnly: false,
    minAmt: 0.0028
  },
  KETH: {
    nativeNetwork: 'kovan',
    symbol: 'kETH',
    name: 'Kovan ETH',
    icon: wETHIcon,
    decimals: 18,
    coinGeckoId: 'ethereum',
    tokenIdentifier: testnetTokens.kWETH,
    nativeOnly: true,
    minAmt: 0.0028
  },
  RETH: {
    nativeNetwork: 'rinkeby',
    symbol: 'rETH',
    name: 'Rinkeby ETH',
    icon: wETHIcon,
    decimals: 18,
    coinGeckoId: 'ethereum',
    tokenIdentifier: testnetTokens.rWETH,
    nativeOnly: true,
    minAmt: 0.0028
  },
  USDT: {
    nativeNetwork: 'kovan',
    symbol: 'USDT',
    name: 'USDT',
    icon: USDTIcon,
    decimals: 6,
    coinGeckoId: 'tether',
    tokenIdentifier: testnetTokens.USDT,
    nativeOnly: false,
    minAmt: 10
  },
  USDC: {
    nativeNetwork: 'kovan',
    symbol: 'USDC',
    name: 'USDC',
    icon: USDCIcon,
    decimals: 6,
    coinGeckoId: 'usd-coin',
    tokenIdentifier: testnetTokens.USDC,
    nativeOnly: false,
    minAmt: 10
  },
  DAI: {
    nativeNetwork: 'kovan',
    symbol: 'DAI',
    name: 'DAI',
    icon: DAIIcon,
    decimals: 18,
    coinGeckoId: 'dai',
    tokenIdentifier: testnetTokens.DAI,
    nativeOnly: false,
    minAmt: 10
  }
  // WMATIC: {
  //   nativeNetwork: 'mumbai',
  //   symbol: 'WMATIC',
  //   name: 'WMATIC',
  //   icon: MaticIcon,
  //   decimals: 18,
  //   coinGeckoId: 'wmatic',
  //   tokenIdentifier: testnetTokens.WMATIC,
  //   nativeOnly: false,
  //   minAmt: 8
  // },
  // MATIC: {
  //   nativeNetwork: 'mumbai',
  //   symbol: 'MATIC',
  //   name: 'MATIC',
  //   icon: MaticIcon,
  //   decimals: 18,
  //   coinGeckoId: 'polygon',
  //   tokenIdentifier: testnetTokens.WMATIC,
  //   nativeOnly: true,
  //   minAmt: 8
  // },
  // USDCPOS: {
  //   nativeNetwork: 'mumbai',
  //   symbol: 'USDC (PoS)',
  //   name: 'USDC (PoS)',
  //   icon: USDCIcon,
  //   decimals: 6,
  //   coinGeckoId: 'usd-coin',
  //   tokenIdentifier: testnetTokens.USDCPOS,
  //   nativeOnly: false,
  //   minAmt: 10
  // }
  // KNX: {
  //   nativeNetwork: 'celo',
  //   symbol: 'KNX',
  //   name: 'KNX',
  //   icon: KNXIcon,
  //   decimals: 18,
  //   // KNX isn't listed on coin gecko yet
  //   coinGeckoId: 'foo',
  //   tokenIdentifier: testnetTokens.KNX,
  //   nativeOnly: false,
  //   minAmt: 1
  // }
}

export const networks: { [key: string]: NetworkMetadata } = {
  alfajores: {
    name: 'Alfajores',
    chainID: 44787,
    domainID: 1000,
    rpcUrl: 'https://alfajores-forno.celo-testnet.org',
    nativeToken: tokens.CELO,
    blockExplorer: 'https://alfajores-blockscout.celo-testnet.org'
  },
  kovan: {
    name: 'Kovan',
    chainID: 42,
    domainID: 3000,
    nativeToken: tokens.KETH,
    rpcUrl: 'https://eth-kovan.alchemyapi.io/v2/aJP38P1ZeHbXP3Td8vVh8vFmxkKT9pnR',
    blockExplorer: 'https://kovan.etherscan.io/'
  },
  rinkeby: {
    name: 'Rinkeby',
    chainID: 4,
    domainID: 2000,
    nativeToken: tokens.RETH,
    rpcUrl:
      'https://eth-rinkeby.alchemyapi.io/v2/uOf-lO18qM7rAT6NOgMAZQoyuS__lhqN',
    blockExplorer: 'https://rinkeby.etherscan.io/'
  }
  // mumbai: {
  //   name: 'Mumbai',
  //   chainID: 80001,
  //   domainID: 2000,
  //   nativeToken: tokens.MATIC,
  //   rpcUrl: 'https://rpc-mumbai.matic.today',
  //   blockExplorer: 'https://explorer-mumbai.maticvigil.com/'
  // }
}

export const representations = representationsDev

export const s3BucketUrl = 'https://optics-staging-community.s3.us-west-2.amazonaws.com/'
