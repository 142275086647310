import * as dev from './config.dev'
import * as main from './config.main'
import { TokenMetadata, NetworkMetadata } from './config.types'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// TODO: Make better. I couldn't get this to work the way I wanted. So someone can fix this later.
const environment = process.env.VUE_APP_OPTICS_ENVIRONMENT
export const tokens = chooseConfig(environment).tokens
export const networks = chooseConfig(environment).networks
export const representations = chooseConfig(environment).representations
export const s3BucketUrl = chooseConfig(environment).s3BucketUrl
export const requiresManualProcessing = chooseConfig(environment).requiresManualProcessing

interface Config { tokens: { [key: string]: TokenMetadata }, networks: { [key: string]: NetworkMetadata }, representations: { [key: string]: object }, s3BucketUrl?: string, requiresManualProcessing?: (remote: string) => boolean }

function chooseConfig (environment: string | undefined): Config {
  console.log('Env: ', environment)
  switch (environment) {
    case 'development':
      return dev

    case 'production':
      return main

    default:
      return dev
  }
}
