
import { defineComponent, computed } from 'vue'
import { NFormItem, NInput, NButton, NTooltip, NInputGroup } from 'naive-ui'
import { useStore } from '@/store'
import { isValidAddress } from '@/utils'
import { headerDestinationAddress, destinationAddressDefault, changeButton, disclaimerDestinationAddressChange, disclaimerAcceptButton } from '../../i18n/main.json'
import { COMPLIANT_ERROR_RESPONSE } from '@celo/compliance'

export default defineComponent({
  components: {
    NFormItem,
    NInput,
    NButton,
    NTooltip,
    NInputGroup
  },

  data () {
    return {
      // user must accept that sending to a custom address
      // can result in a permanent loss of funds
      enableCustomRecipient: false,
      disableChangeButton: false,

      // copy
      headerDestinationAddress,
      destinationAddressDefault,
      changeButton,
      disclaimerDestinationAddressChange,
      disclaimerAcceptButton
    }
  },

  setup () {
    const store = useStore()
    // initial values
    const recipientAddress = computed(() => store.state.userInput.recipientAddress)
    const sending = computed(() => store.state.multiProvider.sending)

    return {
      store,
      recipientAddress,
      sending,
      rule: {
        trigger: ['input'],
        validator () {
          console.log('calling validator', recipientAddress.value)
          if (!isValidAddress(recipientAddress.value)) {
            return new Error('Address is invalid. Please input a valid address.')
          }

          if (store.state.sanctionedList.list.some(address =>
            recipientAddress.value.toLowerCase() === address.toLowerCase())) {
            return new Error(COMPLIANT_ERROR_RESPONSE)
          }

          return true
        }
      }
    }
  },

  methods: {
    handleRecipientAddressChange (address: string) {
      this.store.dispatch('setRecipientAddress', address)
    },

    // User acknowledges risks and enables changing recipient address
    enableChangeAddr () {
      this.enableCustomRecipient = true
      this.disableChangeButton = true
    }
  }
})
