import { BigNumber } from '@ethersproject/bignumber'
import { OpticsContext } from '@optics-xyz/multi-provider'
import { TokenIdentifier } from '@optics-xyz/multi-provider/dist/optics'
import { Address } from '@optics-xyz/multi-provider/dist/utils'
import { xapps } from '@optics-xyz/ts-interface'

export async function getBalances (
  context: OpticsContext,
  token: TokenIdentifier,
  address: Address
) {
  try {
    // @ts-ignore
    const representations = await context.resolveRepresentations(token)
    const balances: Record<number, BigNumber> = {}
    let domain, instance

    for ([domain, instance] of representations.tokens.entries()) {
      console.log({ instance })
      balances[domain] = await instance.balanceOf(address)
    }
    return balances
  } catch (e) {
    console.log('getBalances error', e)
  }
}

export async function getBalance (
  context: OpticsContext,
  token: TokenIdentifier,
  address: Address,
  domain: number
) {
  let key, instance, balance
  const representations = await context.resolveRepresentations(token)
  const tokenEntries = representations.tokens.entries()

  for ([key, instance] of tokenEntries) {
    if (domain === key) {
      balance = await instance.balanceOf(address)
      return balance
    }
  }
}

export async function getNativeBalance (
  context: OpticsContext,
  network: string,
  address: string
) {
  const provider = context.getProvider(network.toLowerCase())
  return provider?.getBalance(address)
}

export async function getERC20Balance (
  provider: any,
  tokenAddress: string,
  account: string
) {
  const tokenContract = xapps.ERC20__factory.connect(tokenAddress as string, provider)
  return await tokenContract.balanceOf(account)
}
